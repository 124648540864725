<template>
  <div class="main-content" v-loading="loading">
    <el-row class="row" type="flex" justify="center">
      <el-col class="form-content">
        <el-form class="school-form" :model="formData" ref="postForm" label-width="100px" label-position="right" :rules="rules">
          <el-row class="sectionLine">
            <div class="inline divSection"></div>
            <div class="inline sectionTitle">基本信息</div>
          </el-row>
          <el-row :gutter="80">
            <el-col :span="12">
              <el-form-item prop="Name" label="院校名称">
                <el-input v-model="formData.Name" placeholder="请输入院校名称" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="Code" label="院校代码">
                <el-input v-model="formData.Code" placeholder="请输入院校代码" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="80">
            <el-col :span="24">
              <el-form-item label="封面图片" prop="Logo">
                <picture-selector v-model="formData.Logo" name="Logo" ref="CoverImage" remarks="200*200" @input="updateForm" :fullPath="formData.LogoFullPath">
                </picture-selector>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="80">
            <el-col :span="12">
              <el-form-item prop="Type" label="院校类型">
                <el-select v-model="formData.Type" style="width:100%" placeholder="请选择院校类型">
                  <el-option v-for="item in typeOptions" :key="item.Key" :label="item.Description" :value="item.Key"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="Nature" label="院校性质">
                <el-select v-model="formData.Nature" style="width:100%" placeholder="请选择院校性质">
                  <el-option v-for="item in natureOptions" :key="item.Value" :label="item.Name" :value="item.Value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="sectionLine">
            <div class="inline divSection"></div>
            <div class="inline sectionTitle">院校简介</div>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="院校简介" prop="Summary">
                <rich-text class="summmy-input" v-model="formData.Summary" ref="Summary" name="Summary" @input="updateForm"></rich-text>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="sectionLine">
            <div class="inline divSection"></div>
            <div class="inline sectionTitle">联系方式</div>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item prop="Phone" label="联系电话">
                <el-input v-model="formData.Phone" placeholder="请输入联系电话"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="Postcode" label="邮编">
                <el-input v-model="formData.Postcode" placeholder="请输入邮编"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="Email" label="电子邮件">
                <el-input v-model="formData.Email" placeholder="请输入电子邮件"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="Fax" label="传真">
                <el-input v-model="formData.Fax" placeholder="请输入传真"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="Area" label="院校位置">
                <el-cascader v-model="formData.Area" :props="casprops" :options="areaTree" style="width:100%" placeholder="请选择院校位置">
                </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="Address" label="详细地址">
                <el-input v-model="formData.Address" placeholder="请输入详细地址"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-button type="primary" @click="submitForm('postForm')">
              保存
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getTreeArea, getDicByCode } from "@/api/base-data";
import { updateSchool, getSchoolInfo, getSchoolType } from "@/api/school";
import PictureSelector from "@/components/base/PictureSelector";
import RichText from "@/components/base/RichText";
import contentTools from "@/mixins/content-tools";
import store from "@/store";
import { isPhone } from "@/utils/validate";
export default {
  components: {
    PictureSelector,
    RichText
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!isPhone(value)) {
        callback(new Error("请输入正确的联系电话"));
      } else {
        callback();
      }
    };
    return {
      id: store.getters.currentId,
      loading: false,
      isLoaded: false,
      casprops: {
        value: "Code",
        label: "Name",
        children: "Children"
      },
      areaTree: undefined,
      typeOptions: [],
      natureOptions: [],
      formData: {
        Name: "",
        Logo: "",
        Summary: ""
      },
      rules: {
        Name: [{ required: true, message: "请输入院校名称", trigger: "blur" }],
        Code: [{ required: true, message: "请输入院校代码", trigger: "blur" }],
        Type: [{ required: true, message: "请选择院校类型", trigger: "blur" }],
        Nature: [
          { required: true, message: "请选择院校性质", trigger: "blur" }
        ],
        Logo: [{ required: true, message: "请选择院校Logo", trigger: "blur" }],
        Phone: [{ required: true, trigger: "blur", validator: validatePhone }],
        Area: [{ required: true, message: "请选择院校位置", trigger: "blur" }],
        Address: [
          { required: true, message: "请输入详细地址", trigger: "blur" }
        ]
      }
    };
  },
  mixins: [contentTools],
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        await Promise.all([
          this.getCity(),
          this.getNatureOptions(),
          this.getSchoolType(),
          this.getSchoolInfo()
        ]);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.isLoaded = true;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          try {
            this.loading = true;
            let isSuccess = false;
            let message = "服务器错误！";
            const postData = Object.assign({}, this.formData);
            [
              postData.ProvinceCode,
              postData.CityCode,
              postData.RegionCode
            ] = postData.Area;
            const res = await updateSchool(postData);
            isSuccess = res.IsSuccess;
            message = res.Message;

            if (isSuccess) {
              this.$message.success(message || "保存成功!");
            } else {
              this.$message.error(message || "保存失败!");
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.loading = false;
          }
        } else {
          this.$message({
            type: "error",
            message: "表单填写错误"
          });
          return;
        }
      });
    },
    updateForm(fieldName, value) {
      console.log("修改参数:", fieldName + "值：", value);
      this.formData[fieldName] = value;
      // console.log(this.formData)
    },
    async getCity() {
      const res = await getTreeArea();
      this.areaTree = this.deleteChildren(res.Result.Children);
    },
    async getNatureOptions() {
      const res = await getDicByCode({ Code: "10022" });
      this.natureOptions = res.Result.Details;
    },
    async getSchoolType() {
      const res = await getSchoolType();
      this.typeOptions = res.Result;
    },
    async getSchoolInfo() {
      const res = await getSchoolInfo(this.id);
      if (res.IsSuccess) {
        const tempData = Object.assign({}, res.Result);
        if (res.Result) {
          tempData.Area = (
            res.Result.ProvinceCode +
            "," +
            res.Result.CityCode +
            "," +
            res.Result.RegionCode
          ).split(",");
        }
        this.formData = Object.assign({}, tempData);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.row {
  flex: 1;
}
.form-content {
  width: 950px;
}
.school-form {
  display: flex;
  flex-direction: column;
  padding: 40px 0;
}
/deep/ .summmy-input {
  justify-content: center;
  display: flex;
  align-items: center;
  .w-e-toolbar {
    flex-wrap: wrap;
  }
}
</style>
